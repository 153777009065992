import * as React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import Page from '../templates/Page'
import Seo from '../components/seo'
import { breakpoints, colors, fontSizes } from '../utils/theme'
import { useInViewportAnimation } from '../utils/hooks'
import { Container, Center, Hero, H2, H3, Text } from '../utils/styles'
import { space } from '../utils/theme'
import { Link } from 'gatsby'

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(17, 1fr);
  grid-gap: ${({ gap }) => (gap ? gap : space[5])};
  margin: ${space[10]} 0 ${space[10]} 0;

  @media (max-width: ${breakpoints.m}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : space[6])};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

const TitleWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled(H3)`
  color: ${({ color }) => (color ? color : 'white')};
  font-size: ${fontSizes['4xl']};
  margin: ${space[8]};
  padding: 0;
  text-align: center;
  z-index: 2;
`

const Partners = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: ${space[2]};
  margin-top: ${space[10]};
  grid-template-areas: '. . . .';

  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: '. .';
    grid-template-rows: 1fr 1fr;
  }
`

const IndexPage = () => {
  useInViewportAnimation()

  return (
    <Page useContainer={false}>
      <Seo title="Home" />
      <Container>
        <Hero>WIR BRINGEN IHRE IDEEN IN DIE PERFEKTE FORM</Hero>
        <Text>
          Herga Konstruktionsbüro ist Ihr Partner für die mechanische
          Realisierung von vielfältigen Projekten. Wir bieten unseren Kunden
          innovative und funktionale Lösungen und lieben die Herausforderung.
          Langjähriges Know how garantiert, dass wir auch anspruchsvolle
          Aufgaben schnell und effizient umsetzen.
          <br />
          <br /> Dabei sind die enge Zusammenarbeit und ein kontinuierlicher
          Dialog mit dem Auftraggeber wichtige Garanten, um zu Produkten mit
          hoher Qualität zu gelangen. Ganz gleich ob Sie technischen
          Konstruktionen, innovativen Leichtbau-Saugsystemen oder
          Roboterlösungen suchen, sind wir Ihr Ansprechpartner.
        </Text>
      </Container>
      <Container>
        <PhotoGrid>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRowEnd: 9,
              gridColumnEnd: 7,
            }}
          >
            <a
              href="https://lightweight-system.com/"
              target="_blank"
              rel="noreferrer"
            >
              <TitleWrapper>
                <Title>Lightweight System</Title>
              </TitleWrapper>
              <StaticImage
                data-inviewport="fade-in-left"
                src="../images/lws.jpg"
                layout="constrained"
                alt="Lightweight System"
                loading="lazy"
                style={{ height: '100%' }}
              />
            </a>
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 7,
              gridRowEnd: 9,
              gridColumnEnd: 13,
            }}
          >
            <Link to="/unternehmen">
              <TitleWrapper>
                <Title color="black">Unternehmen</Title>
              </TitleWrapper>
              <StaticImage
                src="../images/kon.jpg"
                layout="fullWidth"
                alt="Konstruktionen"
                loading="lazy"
                style={{ height: '100%' }}
              />
            </Link>
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 9,
              gridColumnStart: 1,
              gridRowEnd: 17,
              gridColumnEnd: 8,
              transitionDelay: '200ms',
            }}
          >
            <Link to="/leichtbau">
              <TitleWrapper>
                <Title color="white">Leichtbau-Saugsystem</Title>
              </TitleWrapper>
              <StaticImage
                src="../images/J+K_2.jpg"
                layout="fullWidth"
                alt="Leichtbausaugsystem"
                loading="lazy"
                style={{ height: '100%' }}
              />
            </Link>
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 9,
              gridColumnStart: 8,
              gridRowEnd: 17,
              gridColumnEnd: 13,
            }}
          >
            <a href="https://tr-board.com/" target="_blank" rel="noreferrer">
              <TitleWrapper>
                <Title>TR-Board</Title>
              </TitleWrapper>
              <StaticImage
                src="../images/tr.jpg"
                layout="fullWidth"
                alt="TR-Board"
                loading="lazy"
                style={{ height: '100%' }}
              />
            </a>
          </div>
        </PhotoGrid>
      </Container>

      <div
        style={{
          backgroundColor: colors.grey,
          height: '100%',
          width: '100%',
          paddingTop: space[10],
          paddingBottom: space[20],
        }}
      >
        <Container>
          <Center>
            <H2>KUNDEN</H2>
            <Text>Unsere Kunden und langjährigen Partner</Text>
          </Center>
          <Partners>
            <a
              href="https://www.sontheimer.org/de/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/sontheimer.png"
                layout="fullWidth"
                alt="Sonstheimer"
                loading="lazy"
              />
            </a>
            <a href="https://www.erowa.com/de" 
            target="_blank" 
            rel="noreferrer"
            >
              <StaticImage
                src="../images/erowa.png"
                layout="fullWidth"
                alt="Erowa"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.bingpower.de/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/bing-powersystems.png"
                layout="fullWidth"
                alt="Bing Powersystems"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.elringklinger.de/de"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/elring-klinger.png"
                layout="fullWidth"
                alt="Elring Klinger"
                loading="lazy"
              />
            </a>
         
          </Partners>
        </Container>
      </div>
    </Page>
  )
}

export default IndexPage
